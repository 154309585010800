window.__tnt || (window.__tnt = {});
window.__tnt.pay || (window.__tnt.pay = {});

window.__tnt.pay.initPromises = [];
window.__tnt.pay.initialized = false;

window.__tnt.pay.method || (window.__tnt.pay.method = {});

/* tntPayment, runs when the form has initialized */
window.tntPayment = document.createEvent('Event');
tntPayment.initEvent('tnt-payment',true,true,{});

/* tntShowOfferModalTab, runs when the modal displays a tab within it */
window.tntShowOfferModalTab = document.createEvent('Event');
tntShowOfferModalTab.initEvent('tnt-show-offer-modal-tab',true,true,{});

window.tntPaymentSubmitted = document.createEvent('Event');
tntPaymentSubmitted.initEvent('tnt-payment-submitted', true, true, {});

window.tntPaymentSuccess = document.createEvent('Event');
tntPaymentSuccess.initEvent('tnt-payment-success', true, true, {});

window.tntPaymentError = document.createEvent('Event');
tntPaymentError.initEvent('tnt-payment-error', true, true, {});

/*
 * Clear Selected Payment Method
 *
 * @DESC Resets the users payment method selections
 */
__tnt.pay.clearPaymentMethod = function(pf){
    if(pf){
        var payMethodElement = pf.querySelector('.tn-pay-method-output'),
            payMethodAltElement = pf.querySelector('.tn-pay-method-alt'),
            payMethodNewCard = pf.querySelector('.tn-pay-method-new-card'),
            selectedPayMethods = null,
            selectedAltPayMethods = null;
         
        if (payMethodElement) { 
            selectedPayMethods = payMethodElement.querySelectorAll('.wallet-btn');
                
            if (selectedPayMethods) { selectedPayMethods.forEach(function(selectedPayMethod){ selectedPayMethod.classList.remove('tn-pay-method-selected') }) }
        }
            
        if (payMethodAltElement) {
            selectedAltPayMethods = payMethodAltElement.querySelectorAll('.wallet-btn');
    
            if (selectedAltPayMethods) { selectedAltPayMethods.forEach(function(selectedAltPayMethod){ selectedAltPayMethod.classList.remove('tn-pay-method-selected') }) }
        }
            
        if (payMethodNewCard) { payMethodNewCard.classList.remove('tn-pay-method-selected') }
        
        if (pf.dataset.paymentMethod) {
            pf.removeAttribute('data-payment-method');
        }
    }
}

/*
 * Update display of Payment Methods
 * 
 * @DESC shows / hides the pay card entry if multiple payment options are available
 */
__tnt.pay.updatePaymentMethodDisplay = function(pf) {
    if (pf) {
        var payMethodElement = pf.querySelector('.tn-pay-method-output'),
            payMethodSelect = pf.querySelector('.tn-pay-method-select'),
            payMethodAltElement = pf.querySelector('.tn-pay-method-alt'),
            payCardEntry = pf.querySelector('.tn-pay-card-entry');

        if (
            ((payMethodElement && payMethodElement.querySelector('.wallet-btn')) || 
            (payMethodAltElement && payMethodAltElement.querySelector('.wallet-btn'))) &&
            payCardEntry
        ){
            payMethodSelect.hidden = false;
            payCardEntry.hidden = true
        } else if (payCardEntry) {
            payMethodSelect.hidden = true;
            payCardEntry.hidden = false

        }
    }
}

/*
 * Set Payment Method
 *
 * @DESC Set the method being used to pay
 */
__tnt.pay.setPaymentMethod = function(pf, methodName, methodButtonElement, tokenData){
    __tnt.pay.clearPaymentMethod(pf);

    if(pf && methodName){ pf.dataset.paymentMethod = methodName }
    
    try {
        if(tokenData && tokenData.id && tokenData.last_four_digits){
            var sc = pf.dataset.spreedlyContext,
                Spreedly = window[sc];

            Spreedly.setRecache(tokenData.id, {
                'card_type': tokenData.card_type,
                'last_four_digits': tokenData.last_four_digits
            });

            Spreedly.setParam('allow_blank_name', true);
            Spreedly.setParam('allow_expired_date', true);

            var month = pf.querySelector('select[name="tnpay-month"]'),
                year = pf.querySelector('select[name="tnpay-year"]');

            month.value = tokenData.month;
            year.value = tokenData.year;
        }

    } catch(e){ }

    if (methodButtonElement) { methodButtonElement.classList.add('tn-pay-method-selected') }
}

/*
 * Validate Form
 *
 * @DESC Should observe the relevant required form inputs and inform the user when something is wrong
 */
__tnt.pay.validateForm = function(pf){
    /* if form validation has not yet been set up, set up form validation */
    if (pf && !pf.classList.contains('has-form-validation')) {
        pf.classList.add('has-form-validation');

        $(pf).validate({
            onkeyup: false,
            errorClass: 'has-error text-danger',
            errorElement: 'span',
            validClass: 'has-success text-success',
            highlight: function(element, errorClass, validClass) {
                $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).parents('.form-group').removeClass(errorClass).addClass(validClass);
            },
            rules: {
                password_verify: {
                    equalTo: pf.password,
                    hasSequentialNumbers: true,
                    hasRepeatingChars: true,
                    minlength: 8
                }
            },
            messages: {
                password_verify: {
                    equalTo: "The password you entered doesn't match.",
                    minlength: "Passwords must be at least 8 characters long."
                }
            }
        });

    /* if form validation has been initialized, reset the plugin and clear any errors */
    } else if (pf && pf.classList.contains('has-form-validation')) {
        $(pf).validate().resetForm();

        var hasErrors = pf.querySelectorAll('.has-error');

        if(hasErrors){
            hasErrors.forEach(function(errorEl){
                errorEl.classList.remove('has-error');
                errorEl.classList.remove('text-danger');
            });
        }
    }
}

/*
 * Identify Payment Card
 *
 * @DESC presents a list of allowed cards, or identifies a card
 */
__tnt.pay.identifyPaymentCard = function(cardType, cardsEl) {
    /* Credit Card Icon Handler */
    var cardActive = null,
        selectedCard = null,
        cardsAllowed = JSON.parse(cardsEl.getAttribute('data-cards-allowed'));

    if(cardsEl && cardType != null){
        if(!cardsAllowed.includes(cardType)){ 
            cardType = "other" 
        } 
        
        selectedCard = cardsEl.querySelector('[data-cc="' + cardType + '"]');
        cardActive = cardsEl.querySelector('.card-active');
    
        if(cardActive){ cardActive.classList.remove('card-active') }
        if(selectedCard){
            selectedCard.classList.add('card-active');
            cardsEl.classList.add('card-identified');
        }

    }else if(cardType === null && cardActive){
        cardActive.classList.remove('card-active');
        cardsEl.classList.remove('card-identified');
    }
}

/* 
 * Get Location
 *
 * @DESC checks the location information and sets tax data
 */
__tnt.pay.getLocation = function(oForm){
// Gather location inputs
    var paymentZip = oForm.querySelector('input[name=postal_code]'),
        billLocState = oForm.querySelector('select[name=postal-state]'),
        billLocZip = oForm.querySelector('input[name=postal-postcode]'),
        billLocCountry = oForm.querySelector('select[name=postal-country]'),
        billLocAddress = oForm.querySelector('input[name=postal-address]'),
        billLocCity = oForm.querySelector('input[postal-city]');

    try {
        if (typeof paymentZip === 'undefined') {
            // Create hidden postal_code input
            var paymentZip = document.createElement('input');
            
            paymentZip.setAttribute('type', 'hidden');
            paymentZip.setAttribute('name', 'postal_code');
            paymentZip.setAttribute('value', billLocZip.value);

            oForm.appendChild(paymentZip);
        } else if (!paymentZip.value || paymentZip.value == null) {
            // Populate empty postal_code input
            paymentZip.value = billLocZip.value;
        } 
    } catch(e) { console.log(e) }

    try {
        // Ensure purchaser inputs are supplied for gift transactions
        if (oForm.dataset.purchaseType == 'gift') {
            // Get purchaser inputs
            let purchaserState = oForm.querySelector('input[name=purchaser_state]'),
                purchaserZip = oForm.querySelector('input[name=purchaser_zip]'),
                purchaserCountry = oForm.querySelector('input[name=purchaser_country]'),
                purchaserAddress = oForm.querySelector('input[name=purchaser_address]'),
                purchaserCity = oForm.querySelector('input[name=purchaser_city]');

            // Create hidden purchaser input fields if they don't exist
            if (purchaserState === null) {
                purchaserState = document.createElement('input');

                purchaserState.setAttribute('type', 'hidden');
                purchaserState.setAttribute('name', 'purchaser_state');
                purchaserState.value = billLocState.value;

                oForm.appendChild(purchaserState);
            }

            if (purchaserZip === null) {
                purchaserZip = document.createElement('input');

                purchaserZip.setAttribute('type', 'hidden');
                purchaserZip.setAttribute('name', 'purchaser_zip');
                purchaserZip.value = billLocZip.value;

                oForm.appendChild(purchaserZip);
            }

            if (purchaserCountry === null) {
                purchaserCountry = document.createElement('input');

                purchaserCountry.setAttribute('type', 'hidden');
                purchaserCountry.setAttribute('name', 'purchaser_country');
                purchaserCountry.value = billLocCountry.value;

                oForm.appendChild(purchaserCountry);
            }

            if (purchaserAddress === null) {
                purchaserAddress = document.createElement('input');

                purchaserAddress.setAttribute('type', 'hidden');
                purchaserAddress.setAttribute('name', 'purchaser_address');
                purchaserAddress.value = billLocAddress.value;

                oForm.appendChild(purchaserAddress);
            }

            if (purchaserCity === null) {
                purchaserCity = document.createElement('input');

                purchaserCity.setAttribute('type', 'hidden');
                purchaserCity.setAttribute('name', 'purchaser_city');
                purchaserCity.value = billLocCity.value;

                oForm.appendChild(purchaserCity);
            }
        }
    } catch(e) { console.log(e) }
}

/*
 * Enable Submit Button
 *
 * @DESC toggles the submit button based on the TOS checkmark
 */
__tnt.pay.tntTosEnableSubmit = function(tos, paymentButton){
    if (tos.checked) {
        paymentButton.disabled = false;
        
        __tnt.subscription.offers.logCheckoutStepViewed({
            step: __tnt.subscription.offers.getPurchaseStep('tos'),
            label: 'Agreed to terms of service'
        });
        __tnt.subscription.offers.logCheckoutStepCompleted({
            step: __tnt.subscription.offers.getPurchaseStep('tos'),
            label: 'Agreed to terms of service'
        });
    } else {
        paymentButton.disabled = true;
    }
}

/*
 * Enable Form for Known User
 *
 * @DESC Modifies display of the purchase form and federated authentication form based on user status
 */
__tnt.pay.enableFormForKnownUser = function(pf, tntPay) {
    var pfLogin = pf.querySelector('.tnt-purchase-login'),
        afWrapper = tntPay.querySelector('.tnt-payment-federated-auth'),
        modalEl = document.getElementById('access-offers-modal'),
        modalDialogContainer = null,
        activeTab = null;

    if (pf) {
        pf.classList.remove("not-logged-in-form-disabled");
        pf.hidden = false;
    }

    if(afWrapper){ afWrapper.hidden = true }

    if(modalEl){
        modalDialogContainer = modalEl.querySelector('.modal');

        if(modalDialogContainer && modalDialogContainer.classList.contains('in')){
            activeTab = modalDialogContainer.querySelector('.modal-content.active');

            if(activeTab){
                activeTab.classList.remove('modal-scrollable');
                activeTab.removeAttribute('data-scrollable');
            }
        }

        tntShowOfferModalTab.details = {};
        tntShowOfferModalTab.details.tab = activeTab;
        tntShowOfferModalTab.details.resizeTab = true;
        window.dispatchEvent(tntShowOfferModalTab);
    }
}

/*
 * Clear Federated Authentication
 *
 * @DESC clears any values entered into the federated authentication form
 */ 
__tnt.pay.clearFederatedAuth = function(af){
    if (af && af.email) {
        af.email.value = "";
    }
}

/*
 * User Selected to Continue with Email
 *
 * @DESC Uses data from the federated authentication form to populate the purchase form
 */
__tnt.pay.selectedContinueWithEmail = function(pf, af, tntPay) {
    var emailAddressEl = af.querySelector('input[name=email]'),
        emailAddress = "";

    if(emailAddressEl){ emailAddress = emailAddressEl.value }

    if(emailAddress != ""){
        pf.querySelector('input[name=email]').value = emailAddress;
        pf.dataset.continueWithEmail = 'true';
        __tnt.pay.enableFormForKnownUser(pf, tntPay);
    }
    
    return false;
};

/*
 * Handle Purchase Form Submit
 * 
 * @DESC Handles submission of the payment form
 */
__tnt.pay.handlePurchaseSubmit = function(pf, token, method){
    tntPaymentSubmitted.details = {};
    pf.dispatchEvent(tntPaymentSubmitted);

    grecaptcha.enterprise.ready(function(){
        grecaptcha.enterprise.execute(pf.dataset.captchaKey, {action: 'subscribe'}).then(function(vkToken){
            const oData = new URLSearchParams();

            // Set location fields
            __tnt.pay.getLocation(pf);

            // Endpoint
            var purchaseEndpoint = pf.action;

            for (const aPair of new FormData(pf)) {
                oData.append(aPair[0], aPair[1]);
            }

            // Set the token in the hidden form field
            oData.append('tnpay-token', token);
            oData.append('recaptcha_token', vkToken);

            if (method.last_four_digits) {
                oData.append('tnpay-last4', method.last_four_digits);
            }

            // this should dispatch the payment request callback
            var pfMonth = pf.querySelector('.tn-pay-card-entry select[name=tnpay-year]'),
                pfYear = pf.querySelector('.tn-pay-card-entry select[name=tnpay-month]');

            oData.append('tnpay-year', pfYear.value);
            oData.append('tnpay-month', pfMonth.value);

            fetch(purchaseEndpoint, {
                method: 'post',
                credentials: 'include',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: oData
            }).then(function(oResp){
                oPayload = oResp.json().then(function(oPayload){
                    if (oPayload.transaction_id || (pf.dataset.purchaseType == "gift" && oPayload.success == true)){
                        var oProductData = __tnt.subscription.offers.getProduct(),
                            date = new Date();

                        tntPaymentSuccess.details = {};

                        if(oProductData){
                            tntPaymentSuccess.details.payload = oPayload;
                            tntPaymentSuccess.details.transactionData = {
                                transaction_id: oPayload.transaction_id,
                                service_name: oProductData.name,
                                service_rate: oProductData.variant,
                                rate_price: oProductData.price,
                                start_date: date.getTime(),
                                tax: oPayload.total_tax_rate,
                                currency: oProductData.currency,
                                purchaseType: pf.dataset.purchaseType
                            };
                        } else { console.warn("Product data not found"); }

                        pf.dispatchEvent(tntPaymentSuccess);
                        
                        /* LOG PURCHASE COMPLETE EVENT */
                        __tnt.subscription.offers.logOrderCompleted(oPayload.transaction_id);

                    } else {
                        tntPaymentError.details = {};
                        
                        if (oPayload.success && oPayload.message == "Payment method added and Transaction updated"){

                        } else if (oPayload.message) {
                            tntPaymentError.details.message = oPayload.message;

                        } else {
                            tntPaymentError.details.message = "An error occurred";

                        }

                        pf.dispatchEvent(tntPaymentError);

                    }
                });
            });

        });
    });

};

/*
 * Register Credit Card Payments
 */
__tnt.pay.method.newCard = {
    name: "newCard",
    methodString: "New card",
    init: function(pf) {
        var addNewCardEl = pf.querySelector('.tn-pay-method-new-card'),
            cardEntryEl = pf.querySelector('.tn-pay-card-entry');

        if(addNewCardEl && cardEntryEl){
            addNewCardEl.addEventListener('click', function(ev){
                if(pf.dataset.spreedlyContext){
                    Spreedly = window[pf.dataset.spreedlyContext];
                    Spreedly.reload();
                }

                __tnt.pay.clearPaymentMethod(pf);

                pf.removeAttribute('data-payment-method');
                cardEntryEl.hidden = false;
                // this will also need to clear selected payment methods

                addNewCardEl.classList.add('tn-pay-method-selected');
            });
        }
    },
    submitPayment: function(pf) { }
};

document.addEventListener("DOMContentLoaded", function (event) {
    var tntPaymentWrapper = document.querySelectorAll('.tnt-payment');

    if(tntPaymentWrapper && tntPaymentWrapper.length){

        tntPaymentWrapper.forEach(function(tntPay){

            /* Payment Form Setup */
            var pf = tntPay.querySelector('form.tnt-payment-form'),
                af = tntPay.querySelector('form.tnt-payment-federated-auth-form'),
                tos = null,
                paymentButton = null,
                copyToggle = null,
                afEmail = null,
                pfEmail = null,
                pfName = null,
                pfDelivery = null;

            /* Payment Form */
            if(pf){
                tos = pf.tos_confirm;
                paymentButton = pf.payment_button;
                copyToggle = pf.querySelector('.same-info');
                
                /* Payment Form Validation */
                __tnt.pay.validateForm(pf);

                /* automatically copy location information */
                if (copyToggle) {
                    copyToggle.addEventListener("change", function(event){
                        if(event.target.checked){
                            var billingForm = pf.querySelector('.tnt-billing-location'),
                                deliveryForm = pf.querySelector('.tnt-delivery-location');
                                
                            if(billingForm && deliveryForm){
                                try {
                                    deliveryForm.querySelector('input[name=address]').value = billingForm.querySelector('input[name="postal-address"]').value;
                                    deliveryForm.querySelector('input[name=municipality]').value = billingForm.querySelector('input[name="postal-city"]').value;
    
                                
                                    deliveryForm.querySelector('select[name=region]').value = billingForm.querySelector('select[name="postal-state"]').value;
                                    deliveryForm.querySelector('select[name=country]').value = billingForm.querySelector('select[name="postal-country"]').value;
                                    deliveryForm.querySelector('input[name=postal_code]').value = billingForm.querySelector('input[name="postal-postcode"]').value;
                                
                                } catch (e) { /* Address, city not collected */ }
        
                                /** Run tax calculation */
                                __tnt.subscription.offers.calculateTax(pf, pf.postal_code, pf.country);
                            }
                        }
                    });
                }
                
                /* Checkout Step - entering the email */
                pfEmail = pf.querySelector('input[name="email"]');
                if (pfEmail) {
                    pfEmail.addEventListener('focus', function(ev) {
                        __tnt.subscription.offers.logCheckoutStepViewed({
                            step: __tnt.subscription.offers.getPurchaseStep('email'),
                            label: 'Entering email address'
                        });
                    });

                    pfEmail.addEventListener('blur', function(ev) {
                        if (pfEmail.value) {
                            __tnt.subscription.offers.logCheckoutStepCompleted({
                                step: __tnt.subscription.offers.getPurchaseStep('email'),
                                label: 'Email address defined'
                            });
                        }
                    });
                }

                /* Checkout Step - entering the delivery location */
                pfDelivery = pf.querySelector('.delivery-location-required');
                if (pfDelivery) {
                    var locationFields = pfDelivery.querySelectorAll('input[type=text], input[type=tel], select');
                    locationFields.forEach(function(el) {
                        // Set lengths for inputs that are pre-filled
                        if (el.value.length > 0) {
                            __tnt.subscription.offers.locationInputLengths[el.name] = el.value.length;
                        }

                        el.addEventListener('focus', function(ev) {
                            __tnt.subscription.offers.logCheckoutStepViewed({
                                step: __tnt.subscription.offers.getPurchaseStep('location'),
                                label: 'Entering location information'
                            });
                        });

                        el.addEventListener('blur', function(ev) {
                            __tnt.subscription.offers.locationInputLengths[ev.target.name] = ev.target.value.length;

                            if (__tnt.subscription.offers.locationInputsEntered()) {
                                __tnt.subscription.offers.logCheckoutStepCompleted({
                                    step: __tnt.subscription.offers.getPurchaseStep('location'),
                                    label: 'Location information defined'
                                });
                            }
                        });
                    });
                }

                /* Checkout Step - entering the cardholder name */
                pfName = pf.querySelector('input[name="tnpay-name"]');
                if (pfName) {
                    pfName.addEventListener('focus', function(ev) {
                        __tnt.subscription.offers.logCheckoutStepViewed({
                            step: __tnt.subscription.offers.getPurchaseStep('cardholder_name'),
                            label: 'Entering cardholder name',
                            paymentMethod: 'Credit Card'
                        });

                    });

                    pfName.addEventListener('blur', function(ev) {
                        if (pfName.value) {
                            __tnt.subscription.offers.logCheckoutStepCompleted({
                                step: __tnt.subscription.offers.getPurchaseStep('cardholder_name'),
                                label: 'Cardholder name defined',
                                paymentMethod: 'Credit Card'
                            });

                            __tnt.subscription.offers.ccNameLength = pfName.value.length;

                            if (__tnt.subscription.offers.ccInputsEntered()) {
                                __tnt.subscription.offers.logPaymentInfoEntered({
                                    step: __tnt.subscription.offers.getPurchaseStep('payment_info'),
                                    paymentMethod: 'Credit Card'
                                });
                            }
                        }
                    });
                }
            }

            /* Federated Auth Form */
            if (af) {
                afEmail = af.email;

                if (afEmail) {
                    afEmail.addEventListener('focus', function(ev) {
                        __tnt.subscription.offers.logCheckoutStepViewed({
                            step: __tnt.subscription.offers.getPurchaseStep('email'),
                            label: 'Entering email address'
                        });
                    });

                    afEmail.addEventListener('blur', function(ev) {
                        if (afEmail.value) {
                            __tnt.subscription.offers.logCheckoutStepCompleted({
                                step: __tnt.subscription.offers.getPurchaseStep('email'),
                                label: 'Email address defined'
                            });
                        }
                    });
                }
            
                af.addEventListener('submit',function(ev){
                    __tnt.pay.selectedContinueWithEmail(pf, af, tntPay);

                });
            } else {
                __tnt.pay.enableFormForKnownUser(pf, tntPay);

            }

            if(tos && paymentButton){
                tos.addEventListener('change', function(ev){
                    __tnt.pay.tntTosEnableSubmit(tos, paymentButton);
                });
            }

            /* Purchase Specific Init - Happens Every Time */
            pf.addEventListener('tnt-purchase', function(ev){
                var pf = this,
                    afWrapper = null,
                    af = null,
                    errorAlert = null,
                    errorMessage = null;
                
                if (pf && pf.parentElement) {
                    afWrapper = pf.parentElement.querySelector('.tnt-payment-federated-auth');
                    errorAlert = pf.parentElement.querySelector('.tnt-payment-error');
                    errorMessage = pf.parentElement.querySelector('.tnt-payment-message');
                    
                    if (afWrapper) { af = afWrapper.querySelector('form.tnt-payment-federated-auth-form') }
                    if (errorAlert) { errorAlert.hidden = true }
                    if (errorMessage) { errorMessage.hidden = true }
                }
                
                __tnt.pay.clearFederatedAuth(af);
                __tnt.pay.validateForm(pf);
                __tnt.pay.clearPaymentMethod(pf);

                pf.FormInitPromises = [];

                if(__tnt.pay.method){
                    var payMethods = Object.keys(__tnt.pay.method);
                    
                    if(payMethods.length){
                         payMethods.forEach(function(method){
                             if(typeof __tnt.pay.method[method].init == "function"){
                                 __tnt.pay.method[method].init(pf);
                             }
                         });
                    }
                }

                var sc = pf.dataset.spreedlyContext;

                pf.hidden = true;

                pf.FormInitPromises.push(__tnt.loadScript('https://core.spreedly.com/iframe/iframe-v1.min.js'));
                pf.FormInitPromises.push(__tnt.loadScript('https://www.google.com/recaptcha/enterprise.js?render='+pf.dataset.captchaKey));

                /* All Init Promises Completed */
                Promise.all(pf.FormInitPromises).then(function() {
                    __tnt.pay.updatePaymentMethodDisplay(pf);

                    var ccName = pf.querySelector('input[name="tnpay-name"]'),
                        ccNumber = pf.querySelector('.spreedly-number'),
                        ccCvv = pf.querySelector('.spreedly-cvv'),
                        ccFieldStyle = null,
                        spreedlyStyle = "padding: 2px 0 0;",
                        spreedlyKey = null,
                        captchaKey = null,
                        vkEl = pf.querySelector('.tn-vk-container'),
                        spreedlyLoading = pf.querySelector('.tn-pay-card-loading'),
                        spreedlyWrapper = pf.querySelector('.tn-pay-card-wrapper');

                    if(!af){ __tnt.pay.enableFormForKnownUser(pf, tntPay) }
                    if(spreedlyLoading && spreedlyWrapper){
                        spreedlyLoading.hidden = false;
                        spreedlyWrapper.hidden = true;
                    }

                    if(ccName){
                        ccFieldStyle = getComputedStyle(ccName);

                        spreedlyStyle += 'font-family: ' + ccFieldStyle['font-family'] + ';';
                        spreedlyStyle += 'font-size: ' + ccFieldStyle['font-size'] + ';';
                        spreedlyStyle += 'color: ' + ccFieldStyle['color'] + ';';
                    }

                    if(pf && !pf.classList.contains('spreedly-initialized') && ccNumber && ccCvv){
                        pf.classList.add('spreedly-initialized');

                        window[sc] = new SpreedlyPaymentFrame();
                        var Spreedly = window[sc];

                        Spreedly.init(pf.dataset.spreedlyKey, {
                            "numberEl": ccNumber.id,
                            "cvvEl": ccCvv.id
                        });

                        /* Spreedly Ready */
                        Spreedly.on('ready', function(){
                            Spreedly.setFieldType("number", "text");
                            Spreedly.setNumberFormat("prettyFormat");
                            Spreedly.setPlaceholder("number", ccNumber.dataset.spreedlyPlaceholder);
                            Spreedly.setPlaceholder("cvv", ccCvv.dataset.spreedlyPlaceholder);
                            Spreedly.setStyle("number", spreedlyStyle);
                            Spreedly.setStyle("cvv", spreedlyStyle);

                            pf.classList.remove('use-saved-card');

                            if(spreedlyLoading && spreedlyWrapper){
                                spreedlyLoading.hidden = true;
                                spreedlyWrapper.hidden = false;
                            }

                            var cardsEl = pf.querySelector('.tn-spreedly-number-cards');

                            if(cardsEl){
                                __tnt.pay.identifyPaymentCard(null, cardsEl);
                            }

                            /* Spreedly Form Field Event */
                            Spreedly.on('fieldEvent', function(name, type, activeEl, inputProperties) {
                                var cardType = null,
                                    cardElements = null;

                                if (type == 'input') {
                                    if (!pf.classList.contains('use-saved-card') && activeEl == 'number') {
                                        if (inputProperties.cardType != null) {
                                            cardType = inputProperties.cardType;
                                        }

                                        cardElements = pf.querySelector('.tn-spreedly-number-cards');
                                        if(cardElements){
                                            __tnt.pay.identifyPaymentCard(cardType, cardElements);
                                        }
                                    }

                                    if (name == 'number' || name == 'cvv') {
                                        __tnt.subscription.offers.ccValidNumber = inputProperties.validNumber || (inputProperties.maskedNumber && inputProperties.maskedNumber.length > 0);
                                        __tnt.subscription.offers.ccValidCvv = inputProperties.validCvv;

                                        if (__tnt.subscription.offers.ccInputsEntered()) {
                                            // Log payment info entered event
                                            __tnt.subscription.offers.logPaymentInfoEntered({
                                                step: __tnt.subscription.offers.getPurchaseStep('payment_info'),
                                                paymentMethod: 'Credit Card'
                                            });
                                        }
                                    }
                                }
                            });

                            Spreedly.on('paymentMethod', function(token, oMethod){
                                __tnt.pay.handlePurchaseSubmit(pf, token, oMethod);
                            });

                            Spreedly.on("recache", function(token, oMethod){
                                __tnt.pay.handlePurchaseSubmit(pf, token, oMethod);
                            });

                        }); // Spreedly Ready

                    } else {
                        if(spreedlyLoading && spreedlyWrapper){
                            spreedlyLoading.hidden = true;
                            spreedlyWrapper.hidden = false;
                        }
                    }

                    if(afWrapper && __tnt.user.authToken == null && !pf.email.value && pf.dataset.authRequired != 'false'){
                        afWrapper.hidden = false;
                        pf.hidden = true;

                    } else if(afWrapper && pf && tntPay) {
                        afWrapper.hidden = true;
                        __tnt.pay.enableFormForKnownUser(pf, tntPay);
                    }

                });
            });

            /* 
             * Form Submit
             */
            pf.addEventListener('submit', function(ev){
                ev.preventDefault();

                var requiredFields = {},
                    oName = null,
                    oMonth = null,
                    oYear = null,
                    oZip = null,
                    bCheck = true;

                if(pf){
                    // Gather location inputs
                    var paymentZip = pf.querySelector('input[name=postal_code]'),
                        billLocState = pf.querySelector('select[name=postal-state]'),
                        billLocZip = pf.querySelector('input[name=postal-postcode]'),
                        billLocCountry = pf.querySelector('select[name=postal-country]'),
                        Spreedly = null;
                    
                    if(pf.dataset.spreedlyContext){
                        Spreedly = window[pf.dataset.spreedlyContext];
                    }

                    // Get required, non-sensitive, values from host page
                    oName = pf.querySelector('input[name=tnpay-name]');
                    oMonth = pf.querySelector('select[name=tnpay-month]');
                    oYear = pf.querySelector('select[name=tnpay-year]');
                    oZip = pf.querySelector('input[name=postal_code]');

                    if(oName){ requiredFields["full_name"] = oName.value } else { bCheck = false; sError += " name not found," }
                    if(oMonth){ requiredFields["month"] = oMonth.value } else { bCheck = false; sError += " month not found," }
                    if(oYear){ requiredFields["year"] = oYear.value } else { bCheck = false; sError += " year not found," }
                    if(oZip){ requiredFields["zip"] = oZip.value } else { bCheck = false; sError += " zip code not found, " }
                    
                    /* Payment Method Submit Handler */
                    if (bCheck && pf.dataset.paymentMethod && __tnt.pay.method[pf.dataset.paymentMethod] && typeof __tnt.pay.method[pf.dataset.paymentMethod].submitPayment == "function") {
                        __tnt.pay.method[pf.dataset.paymentMethod].submitPayment(pf);

                    /* Generic Spreedly Submit Handler */
                    } else if (bCheck) {
                        Spreedly.tokenizeCreditCard(requiredFields);

                    }
                }

                return false;
            });

            /*
             * Payment Success
             */
            pf.addEventListener('tnt-payment-success', function(ev){
                __tnt.user.loginSuccess = true;
                __tnt.user.purchasedAccess = true;
                    
                if (__tnt.user && typeof __tnt.user.updateLoggedIn == "function") {
                    __tnt.user.updateLoggedIn();
                } else if (__tnt.user && typeof __tnt.user.update == "function") {
                    __tnt.user.update();
                }
            });

            /*
             * Payment Error
             */
            pf.addEventListener('tnt-payment-error', function(ev){
                var pf = this,
                    errorAlert = null,
                    errorAlertMessage = null,
                    errorButton = document.createElement('a'),
                    forgotText = null;

                if (pf) { errorAlert = pf.parentElement.querySelector('.tnt-payment-error') }

                if(errorAlert){
                    errorAlertMessage = errorAlert.querySelector('.error-message');

                    if(ev.details && ev.details.message){
                        if (errorAlertMessage && ev.details.message) { errorAlertMessage.innerHTML = ev.details.message }
                        if (ev.details.message == "Invalid password or account does not exist") {

                            if(errorAlertMessage){
                                forgotText = errorMessage.dataset.forgotText;

                                if(forgotText){ errorButton.innerHTML = forgotText }
                            } else {
                                errorButton.innerHTML = "Forgot your password?";
                            }
                            errorButton.classList.add("alert-link", "btn-block");
                            errorButton.href = "javascript:void(0)";
                            errorButton.addEventListener('click',function(ev){
                                var modal = document.getElementById('access-offers-modal'),
                                    modalTabForgot = document.getElementById('user-modal-tab-forgot');

                                __tnt.subscription.access.showTab(modalTabForgot, modal);
                            });

                            errorAlertMessage.appendChild(errorButton);
                        }

                        errorAlert.hidden = false;
                        errorAlert.classList.remove('fade');
                    }
                }

            });


            /*
             * Update Purhcase Form base on User Status
             */
            window.addEventListener('tnt-user-status',function(ev){
                var tntPaymentWrapper = document.querySelectorAll('.tnt-payment');

                if(tntPaymentWrapper){
                    tntPaymentWrapper.forEach(function(tntPay){
                        var pf = tntPay.querySelector('form.tnt-payment-form'),
                            afWrapper = tntPay.querySelector('form.tnt-payment-federated-auth'),
                            pfLogin = null;
                        
                        if (pf) {
                            pfLogin = pf.querySelector('.tnt-purchase-login');

                            if(__tnt.user.authToken == null){
                                pf.classList.add("not-logged-in-form-disabled");
                            } else {
                                __tnt.pay.enableFormForKnownUser(pf, tntPay);
                            }
                        }
                    });
                }
            });

        }); // End TNT Payment Wrapper Loop
    }

    __tnt.user.update();

});
